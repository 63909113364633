// src/Sidebar.js
import React from "react";
import { useTranslation } from "react-i18next";
import ExpenseSvg from "../../assets/svgs/ExpenseSvg";
import InvoiceSvg from "../../assets/svgs/InvoiceSvg";
import FlightsListSvg from "../../assets/svgs/FlightsListSvg";
import OrderSvg from "../../assets/svgs/OrderSvg";
import { OperatorSvg, CustomersSvg, SettingsSvg } from "../../assets/svgs";
import { Button } from "../../UI/Button/Button";
import { useLocation } from "react-router-dom";

import styles from "../../index.module.css";

const Sidebar = ({ user }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const sidebarItems = [
    user?.roles?.includes("SUPER_ADMIN") && {
      id: 0,
      name: t("allAccounts"),
      route: "/all-accounts",
      svg: <OperatorSvg />,
      subMenu: [],
      active: true,
    },
    user?.roles?.includes("SUPER_ADMIN") && {
      id: 1,
      name: t("allCustomers"),
      route: "/customers",
      svg: <CustomersSvg />,
      subMenu: [],
      active: true,
    },
    user?.roles?.includes("OPERATOR") && {
      id: 2,
      name: t("myCustomers"),
      route: "/customers",
      svg: <CustomersSvg />,
      subMenu: [],
      active: true,
    },
    user?.roles?.includes("SUPER_ADMIN") && {
      id: 3,
      name: t("allOrders"),
      route: "/orders",
      svg: <OrderSvg />,
      subMenu: [],
      active: true,
    },
    user?.roles?.includes("OPERATOR") && {
      id: 4,
      name: t("myOrders"),
      route: "/orders",
      svg: <OrderSvg />,
      subMenu: [],
      active: true,
    },
    user?.roles?.includes("OPERATOR") && {
      id: 5,
      name: t("myInvoices"),
      route: "/invoice",
      svg: <InvoiceSvg />,
      subMenu: [],
      active: true,
    },
    user?.roles?.includes("SUPER_ADMIN") && {
      id: 6,
      name: t("allInvoices"),
      route: "/invoice",
      svg: <InvoiceSvg />,
      subMenu: [],
      active: true,
    },
    user?.roles?.includes("OPERATOR") && {
      id: 7,
      name: t("myServices"),
      route: "/my-services",
      svg: <FlightsListSvg />,
      subMenu: [],
      active: true,
    },
    user?.roles?.includes("SUPER_ADMIN") && {
      id: 8,
      name: t("allServices"),
      route: "/all-services",
      svg: <FlightsListSvg />,
      subMenu: [],
      active: true,
    },
    user?.roles?.includes("SUPER_ADMIN") && {
      id: 9,
      name: t("expenses"),
      route: "/expense",
      svg: <ExpenseSvg />,
      subMenu: [],
      active: true,
    },
    user?.roles?.includes("SUPER_ADMIN") && {
      id: 10,
      name: t("settings"),
      route: "/settings",
      svg: <SettingsSvg />,
      subMenu: [
        {
          name: t("expencesCategory"),
          route: "/expense-category",
        },
        {
          name: t("serviceCompany"),
          route: "/service-company",
        },
        {
          name: t("serviceType"),
          route: "/service-type",
        },
      ],
      active: true,
    },
  ].filter(Boolean);

  return (
    <div className={`${styles.sidebar} admin-sidebar`}>
      {sidebarItems.map((item) => (
        <Button
          key={item.id}
          id={item.id}
          label={item.name}
          route={item.route}
          element={"side-admin-button"}
          svg={item.svg}
          subMenu={item.subMenu}
          active={location.pathname === item.route}
          subMenuActive={location.pathname.includes(item.subMenu?.route)}
        />
      ))}
    </div>
  );
};

export default Sidebar;
