import { useState } from "react";
import { Input } from "../../UI/Input/Input";

import styles from "./CompanyAccount.module.css";

function CompanyAccount() {
  const [popUpData, setPopUpData] = useState({
    company_name: "",
    address: "",
    currency: "",
  });

  const inputs = [
    {
      title: "Company Name",
      name: "company_name",
      type: "default",
      placeholder: "Company Name",
      value: popUpData.company_name,
      onChange: (e) =>
        setPopUpData((prev) => ({
          ...prev,
          [e.target.name]: e.target.value,
        })),
    },
    {
      title: "Address",
      name: "address",
      type: "default",
      placeholder: "Address",
      value: popUpData.address,
      onChange: (e) =>
        setPopUpData((prev) => ({
          ...prev,
          [e.target.name]: e.target.value,
        })),
    },
    {
      title: "Email",
      name: "email",
      type: "default",
      placeholder: "Email",
      value: popUpData.email,
      onChange: (e) =>
        setPopUpData((prev) => ({
          ...prev,
          [e.target.name]: e.target.value,
        })),
    },
    {
      title: "Password",
      name: "password",
      type: "default",
      inputType: "password",
      placeholder: "password",
      value: popUpData.email,
      onChange: (e) =>
        setPopUpData((prev) => ({
          ...prev,
          [e.target.name]: e.target.value,
        })),
    },
  ];

  const handleInputChange = (e, params) => {
    const { name, onChange } = params;

    let data;
    if (!e.target) {
      data = {
        target: {
          value: e,
          name,
        },
      };
      return onChange(data);
    }

    onChange(e);
  };
  return (
    <div className={styles.account_main}>
      {inputs?.map((params, index) => {
        return (
          <div className="exchange-input-wrapper" key={index}>
            <Input
              key={index}
              type={params?.type}
              label={params.title}
              name={params.name}
              customStyles={{ width: "100%" }}
              selectHandler={(opt) => {
                handleInputChange(opt, params);
              }}
              placeholder={params?.placeholder}
              onChange={(e) => handleInputChange(e, params)}
              defaultData={params?.options}
              customInputStyles={{
                border: "1px solid rgba(255, 255, 255, 0.1)",
              }}
              editable={true}
            />
          </div>
        );
      })}
    </div>
  );
}

export default CompanyAccount;
