export const getProfileInputs = (formData, setFormData) => [
  {
    title: "Company Name",
    name: "name",
    type: "default",
    placeholder: "Enter Company Name",
    value: formData.name || "",
    onChange: (e) =>
      setFormData((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      })),
  },
  {
    title: "Email",
    name: "email",
    type: "default",
    placeholder: "Enter Email",
    value: formData.email || "",
    onChange: (e) =>
      setFormData((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      })),
  },
  {
    title: "Phone Number",
    name: "phone_number",
    type: "label-input-phone-number",
    placeholder: "Phone Number",
    value: formData.phone_number || { code: "+995", flag: "🇬🇪", number: "" },
    onChange: (data) =>
      setFormData((prev) => ({
        ...prev,
        phone_number: data,
      })),
  },
  {
    title: "Registration ID",
    name: "registration_id",
    type: "default",
    placeholder: "Enter Company Name",
    value: formData.registration_id || "",
    onChange: (e) =>
      setFormData((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      })),
  },

  {
    title: "Company Address",
    name: "address",
    type: "default",
    placeholder: "Enter Company Address",
    value: formData.address || "",
    onChange: (e) =>
      setFormData((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      })),
  },
];

export const getUploadInputs = (formData, setFormData, company) => [
  {
    title: "Company Logo",
    name: "companyLogo",
    type: "label-input-upload",
    placeholder: "Upload Company Logo",
    value: company.main_logo || "", // Directly access companyLogo
    uploadContent: "Upload Company Logo",
    onChange: (file) => {
      // Store the file directly in formData
      setFormData((prev) => ({
        ...prev,
        companyLogo: file, // Set the file directly
      }));
    },
  },
  {
    title: "Ring Logo",
    name: "ringLogo",
    type: "label-input-upload",
    placeholder: "Upload Ring Logo",
    value: company.ring_logo || "", // Directly access ringLogo
    uploadContent: "Upload Ring Logo",
    onChange: (file) => {
      setFormData((prev) => ({
        ...prev,
        ringLogo: file, // Set the file directly
      }));
    },
  },
  {
    title: "Invoice Logo",
    name: "invoiceLogo",
    type: "label-input-upload",
    placeholder: "Upload Invoice Logo",
    value: company.invoice_logo || "", // Directly access invoiceLogo
    uploadContent: "Upload Invoice Logo",
    onChange: (file) => {
      setFormData((prev) => ({
        ...prev,
        invoiceLogo: file, // Set the file directly
      }));
    },
  },
];

//payment method
export const getPaymentMethodInputs = (newBankDetails, setNewBankDetails) => [
  {
    title: "Bank Name",
    name: "bank_name",
    type: "default",
    placeholder: "Enter Company Name",
    value: newBankDetails.bank_name || "",
    onChange: (e) =>
      setNewBankDetails((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      })),
  },
  {
    title: "Bank Account Number",
    name: "account_number",
    type: "default",
    placeholder: "Enter Company Name",
    value: newBankDetails.account_number || "",
    onChange: (e) =>
      setNewBankDetails((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      })),
  },
];
///chatbot inputs
export const getChatbotInputs = (formData, setFormData) => [
  {
    title: "Facebook Chat ID",
    name: "fb_chat_id",
    type: "default",
    placeholder: "Enter Company Name",
    value: formData.fb_chat_id || "",
    onChange: (e) =>
      setFormData((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      })),
  },
  {
    title: "OpenAI API Key",
    name: "openai_api_key",
    type: "default",
    placeholder: "Enter Company Name",
    value: formData.openai_api_key || "",
    onChange: (e) =>
      setFormData((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      })),
  },
  {
    title: "Chatbot Organization ID",
    name: "chatbot_organization_id",
    type: "default",
    placeholder: "Enter Company Name",
    value: formData.chatbot_organization_id || "",
    onChange: (e) =>
      setFormData((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      })),
  },
  {
    title: "Facebook Chat ID",
    name: "fb_chat_id",
    type: "default",
    placeholder: "Enter Facebook Chat ID",
    value: formData.fb_chat_id || "",
    onChange: (e) =>
      setFormData((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      })),
  },
  {
    title: "Project ID",
    name: "project_id",
    type: "default",
    placeholder: "Enter Facebook Chat ID",
    value: formData.project_id || "",
    onChange: (e) =>
      setFormData((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      })),
  },
  {
    title: "Assistant ID",
    name: "assistant_id",
    type: "default",
    placeholder: "Enter Facebook Chat ID",
    value: formData.assistant_id || "",
    onChange: (e) =>
      setFormData((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      })),
  },
  {
    title: "Page Access Token",
    name: "page_access_token",
    type: "default",
    placeholder: "Enter Facebook Chat ID",
    value: formData.page_access_token || "",
    onChange: (e) =>
      setFormData((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      })),
  },
];
