import React from "react";
import styles from "./ServiceItem.module.css";

const ServiceDetailsTable = ({ data }) => {
  return (
    <table className={styles.table}>
      <tbody>
        <tr>
          <td className={styles.td}>Amount</td>
          <td className={styles.td}>{data.amount}</td>
        </tr>
        <tr>
          <td className={styles.td}>Baggage Type</td>
          <td className={styles.td}>{data.baggage_type || "N/A"}</td>
        </tr>
        <tr>
          <td className={styles.td}>Baggage Weight</td>
          <td className={styles.td}>{data.baggage_weight ?? "N/A"}</td>
        </tr>
        <tr>
          <td className={styles.td}>Destination</td>
          <td className={styles.td}>{data.destination}</td>
        </tr>
        <tr>
          <td className={styles.td}>Location</td>
          <td className={styles.td}>{data.location}</td>
        </tr>
        <tr>
          <td className={styles.td}>Start Date</td>
          <td className={styles.td}>
            {new Date(data.start_date).toLocaleString()}
          </td>
        </tr>
        <tr>
          <td className={styles.td}>End Date</td>
          <td className={styles.td}>
            {new Date(data.end_date).toLocaleString()}
          </td>
        </tr>
        <tr>
          <td className={styles.td}>Service Type</td>
          <td className={styles.td}>{data.service_type_id?.name || "N/A"}</td>
        </tr>
        <tr>
          <td className={styles.td}>Company</td>
          <td className={styles.td}>{data.company_id?.name || "N/A"}</td>
        </tr>
        <tr>
          <td className={styles.td}>Operator ID</td>
          <td className={styles.td}>{data.operator_id}</td>
        </tr>
        <tr>
          <td className={styles.td}>Performance Status</td>
          <td className={styles.td}>{data.performance_status}</td>
        </tr>
        <tr>
          <td className={styles.td}>Status</td>
          <td className={styles.td}>{data.status}</td>
        </tr>
        <tr>
          <td className={styles.td}>Visa Type</td>
          <td className={styles.td}>{data.visa_type || "N/A"}</td>
        </tr>
        <tr>
          <td className={styles.td}>Zone</td>
          <td className={styles.td}>{data.zone || "N/A"}</td>
        </tr>
        <tr>
          <td className={styles.td}>Created At</td>
          <td className={styles.td}>
            {new Date(data.createdAt).toLocaleString()}
          </td>
        </tr>
        <tr>
          <td className={styles.td}>Updated At</td>
          <td className={styles.td}>
            {new Date(data.updatedAt).toLocaleString()}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default ServiceDetailsTable;
