// orderReducer.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  popupShow: "",
  popUpData: {
    _id: "",
    operator_id: "",
    full_name: "",
    phone_number: { code: "+995", flag: "🇬🇪", number: "" },
    national_ID_number: "",
    payment_method: "",
    start_date: "",
    end_date: "",
    location: "",
    destination: "",
    detales: "",
    note: "",
    total_price: 0,
    discount: "",
    passenger: "",
    services: [],
    passengers: [],
    hotel_name: "",
    visa_type: "",
    invitation_type: "",
    insurance_provider: "",
    zone: "",
    baggage_type: "",
    baggage_weight: "",
  },
  isLoading: false,
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setPopupShow: (state, action) => {
      state.popupShow = action.payload;
    },
    hidePopup: (state) => {
      state.popupShow = "";
    },
    setPopUpData: (state, action) => {
      state.popUpData = action.payload;
    },
    clearPopupData: (state) => {
      state.popUpData = initialState.popUpData;
    },
  },
});

export const { setPopupShow, hidePopup, setPopUpData, clearPopupData } =
  orderSlice.actions;

export default orderSlice.reducer;
